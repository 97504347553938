<template>
    <div>          
        <v-card-text class="pt-15 mb-0 pb-0">                        
            <h5>How do you like to handle booking requests?  (You can change this later)</h5>                        
        </v-card-text>  
        <v-radio-group v-model="value.bookingFlow">
            <v-card-text class="mt-0 pt-0">
                <div class="eg-todo-item d-flex justify-space-between flex-wrap align-center mb-5- white- pa-4- rounded mt-0 mb-4">
                    <div class="d-flex align-center">
                        <v-radio                    
                            class="mt-1"                                                            
                            :value="'immediate'"
                        ></v-radio>
                        <h6 class="ma-0">
                            Immediate Booking
                        </h6>
                    </div>
                    <div style="width: 60%;">
                        <span>The sessions requested by your clients will be immediately confirmed by default. All that will be left is for you to show up.</span>
                    </div>
                </div>
                <div class="eg-todo-item d-flex justify-space-between flex-wrap align-center mb-5- white- pa-4- rounded">
                    <div class="d-flex align-center">
                        <v-radio                       
                            class="mt-1"                                                                             
                            :value="'requestbefore'"
                        ></v-radio>
                        <h6 class="ma-0">
                            Request Before Booking
                        </h6>
                    </div>
                    <div style="width: 60%;">
                        <span>Your clients will have to issue a Request to arrange a session with you - you will have to confirm it before proceeding with the booking.</span>
                    </div>
                </div>
            </v-card-text>  
        </v-radio-group>    
        <v-card-text class="pt-15 mb-0 pb-0 mt-6">                        
            <h5>Select your Cancellation Policy</h5>                        
        </v-card-text>  
        <v-radio-group v-model="value.cancelationFlow">
            <v-card-text class="mt-0 pt-0">
                <div class="eg-todo-item d-flex justify-space-between flex-wrap align-center mb-5- white- pa-4- rounded mt-0 mb-4">
                    <div class="d-flex align-center">
                        <v-radio                    
                            class="mt-1"                                                            
                            :value="'norefund'"
                        ></v-radio>
                        <h6 class="ma-0">
                            No Refund
                        </h6>
                    </div>
                    <div style="max-width: 60%;">
                        <span></span>
                    </div>
                </div>
                <div class="eg-todo-item d-flex justify-space-between flex-wrap align-center mb-5- white- pa-4- rounded mt-0 mb-4">
                    <div class="d-flex align-center">
                        <v-radio                    
                            class="mt-1"                                                            
                            :value="'50percent'"
                        ></v-radio>
                        <h6 class="ma-0">
                            50% Refund
                        </h6>
                    </div>
                    <div style="max-width: 60%;">
                        <span></span>
                    </div>
                </div>
                <div class="eg-todo-item d-flex justify-space-between flex-wrap align-center mb-5- white- pa-4- rounded">
                    <div class="d-flex align-center">
                        <v-radio                       
                            class="mt-1"                                                                             
                            :value="'requestbefore'"
                        ></v-radio>
                        <h6 class="ma-0">
                            Flexible / Time-based
                        </h6>
                    </div>
                    <div style="width: 60%;">
                        <span>Free cancellation 1 hour prior to booking. If they cancel 30 mins prior to the appointment they get 50% refund.</span>
                    </div>
                </div>
            </v-card-text>  
        </v-radio-group>  
    </div>
</template>
<script>
export default {
    model: {
        prop: "value",
        event: "change"
    },

    props: {
        largefieldheaders: {
            type: Boolean,
            default: () => false,
            required: false
        },
        value: {
            type: Object,
            default: () => ({
                bookingFlow: undefined,
                cancelationFlow: undefined,
            }),
        },
    },

    methods: {
        emit() {
            this.$emit('change', this.value);
        }
    },
}
</script>