<template>
<v-list outlined style="border-radius: 20px; background-color: #DDDDDD33">
    <v-list-item>
        <v-list-item-content class="pb-0">
            <h5>Location #{{index+1}}</h5>
        </v-list-item-content>
    </v-list-item>        
    <v-list-item>
        <v-list-item-content class="pb-0">
            <span>Drag marker if you need to correct pin placement</span>
        </v-list-item-content>                                        
    </v-list-item> 
    <v-list-item>
        <v-list-item-content class="pb-0">
            <!--<google-map :pois="pois" :draggable="true" :zoom="18" @ondrag="markerdragged" />-->
            <GoogleMapX @onresult="addressChanged" :pinCoords="{lat: value.latcoord, lng: value.lngcoord}" />
        </v-list-item-content>                                        
    </v-list-item>  
    <v-list-item>
        <v-list-item-content class="pb-0">
            <v-checkbox label="Primary Location" />
        </v-list-item-content>
    </v-list-item>  
    <v-list-item>
        <v-list-item-content class="pb-0 pt-10">
            <h6>Address</h6>
        </v-list-item-content>
    </v-list-item> 
    <v-list-item>
        <v-list-item-content class="pb-0 mt-3">
            <v-text-field 
                label="Street" 
                v-model="value.streetaddress"    
                :rules="[]"
                :validate-on-blur="false"
                class="col-12 p-0 pr-4"
                style="padding: 0px 0px;"                            
            />
            <v-text-field 
                label="Postal Code" 
                v-model="value.postalcode"    
                :rules="[]"
                :validate-on-blur="false"
                class="col-4 p-0 pr-4"
                style="padding: 0px 0px;"                            
            />
            <v-text-field 
                label="City" 
                v-model="value.businessmunicipality"    
                :rules="[]"
                :validate-on-blur="false"
                class="col-8 p-0 pr-4"
                style="padding: 0px 0px;"                
            />
            <v-text-field 
                label="State" 
                v-model="value.businessprefecture"    
                :rules="[]"
                :validate-on-blur="false"
                class="col-12 p-0 pr-4"
                style="padding: 0px 0px;"
            />
        </v-list-item-content>                                        
    </v-list-item>    
    <!-- <v-list-item>
        <v-list-item-content class="pb-0">
            <h6>Contact</h6>
        </v-list-item-content>
    </v-list-item>                              
    <v-list-item>                                        
        <v-list-item-content class="pb-0">                                            
            <v-text-field 
                label="Phone No." 
                v-model="value.phoneno"    
                :rules="[]"
                :validate-on-blur="false"
                class="col-6 p-0 pr-4"
                style="padding: 0px 0px;"
            />                                   
            <v-text-field 
                label="Email" 
                v-model="value.email"    
                :rules="[]"
                :validate-on-blur="false"
                class="col-6 p-0 pr-4"
                style="padding: 0px 0px;"
            />
            
        </v-list-item-content>
    </v-list-item>  -->
</v-list>      
</template>

<script>
import api from "src/api/index";
import moment from "moment";
import axios from "axios";
import GoogleMap from "@/components/core/GoogleMap";
import GoogleMapX from "@/components/core/GoogleMapX";

import _ from "lodash";

import { mapGetters, mapActions } from "vuex";

export default {
    // metaInfo: {
    //     // title will be injected into parent titleTemplate
    //     title: 'Professional Setip'
    // },    
    components: {        
        GoogleMap,
        GoogleMapX    
    },
    model: {
        prop: "value",
        event: "change"
    },
    // props: ['ttindex', 'modelValue'],
    //props: ['index', 'value'],

    props: {
        index: Number,
        value: {
            type: Object,
            default: () => ({
                businessprefecture: undefined,
                businessmunicipality: undefined,
                streetaddress: undefined,
                postalcode: undefined,
                phoneno: undefined,
                email: undefined,
                latcoord: undefined,
                lngcoord: undefined,
            }),
        },
    },

    // props: {
    //     ttindex: null,
    //     modelValue: null,
    // },

    
    
    data() {
        return {            
            searchaddress: null,
            address: this.value.address,            

            // businessprefecture: this.value.businessprefecture,
            // businessmunicipality: this.value.businessmunicipality,            
            // streetaddress: this.value.streetaddress,
            // postalcode: this.value.postalcode,
            // phoneno: this.value.phoneno,
            // email: this.value.email,
            // latcoord: this.value.latcoord,
            // lngcoord: this.value.lngcoord,                            
            
            loading: false,
            addressresults: [],
            resultselected: false,
        }
    },    
    computed: {
        // pois() {
        //     if (typeof this.address === 'object' && this.address !== null) {
        //         return [{
        //             position: {
        //                 lat: this.address.data.lat,
        //                 lng: this.address.data.lng
        //             }
        //         }]
        //     }
        //     else {
        //         return [];
        //     }
        // }
    },
    methods: {
        ...mapActions([]),
        addressChanged: function (addressInfo) {
            //alert(JSON.stringify(addressInfo));
            if(addressInfo.lat) {
                this.value.latcoord = addressInfo.lat;
            }
            if(addressInfo.lng) {
                this.value.lngcoord = addressInfo.lng;
            }

            if(addressInfo.streetaddress) {
                this.value.streetaddress = addressInfo.streetaddress;
            }
            if(addressInfo.municipality) {
                this.value.businessmunicipality = addressInfo.municipality;
            }
            if(addressInfo.postalcode) {
                this.value.postalcode = addressInfo.postalcode;
            }
            if(addressInfo.prefecture) {
                this.value.businessprefecture = addressInfo.prefecture;
            }
            // if(addressInfo.country) {
                
            // }
        },    
    },
    async created() {
    },
    async mounted() {
        
    }
}
</script>
<style lang="scss" scoped>

</style>
