<template>
    <v-row>
        <v-col cols="12" lg="3">
            <base-card>
                <v-card-text>
                    <div class="d-flex align-center justify-center mb-6">
                        <div class="text-center">
                            <v-img
                                height="128"
                                width="128"
                                src="@/assets/images/faces/2.jpg"
                                class=" rounded-circle"
                                alt=""
                            ></v-img>
                            <div style="font-size: 0.8em">
                                Click image to change
                            </div>
                            <h5 class="font-weight-bold my-3">
                                Alex Padatos
                            </h5>
                        </div>
                    </div>
                    <v-divider class="my-4" />
                    <div v-if="mainrole!=='Customer'" class="d-flex justify-space-between itesm-center mt-6">
                        <h5  class="ma-0">
                            {{ mainrole }}
                        </h5>
                        <v-btn color="primary" class="rounded-pill" depressed :to="'/mng/profiledetails'">
                            Preview Profile
                        </v-btn>
                    </div> 
                    <div v-if="mainrole==='Customer'" class="d-flex justify-space-between itesm-center mt-2">
                        <v-btn color="primary" class="rounded-pill" depressed :to="'/mng/profiledetails'" style="margin-left: auto; margin-right: auto;">
                            Preview Profile
                        </v-btn>
                    </div>    
                    <v-divider v-if="mainrole!=='Customer'" class="my-4" />
                    <div class="d-flex- justify-space-between itesm-center mt-6">
                        <v-select         
                            placeholder=""                                                            
                            v-model="help_visibility"
                            :items="['Hidden','Public']"                 
                            label="Profile Visibility" 
                            
                            :rules="[]"     
                            required                                                         
                            :clearable="true"                      
                        /> 
                        <br/>
                        <v-alert v-if="mainrole!=='Customer'" 
                        border="right"
                        colored-border
                        type="error"
                        elevation="2"
                        style="pointer: cursor;"
                        @click="()=>{}"
                    >
                        Click <strong>here</strong> to setup your availability before you can start accepting bookings.
                    </v-alert>
                        <!-- <v-alert v-if="mainrole!=='Customer'" dense type="info">
                            Click <strong>here</strong> to setup your availability before you can start accepting bookings.
                        </v-alert> -->
                        <v-switch
                            v-if="mainrole!=='Customer'"
                            v-model="ex11"
                            label="Can Accept Bookings"
                            color="orange"
                            value="orange"
                            hide-details
                            disabled
                        ></v-switch>
                        <!-- <h5 class="ma-0">
                            Therapist
                        </h5>
                        <v-btn color="primary" class="rounded-pill" depressed :to="'/mng/profiledetails'">
                            Preview Profile
                        </v-btn> -->
                    </div> 
                    <v-divider  class="my-4" />
                    <div class="d-flex align-center justify-space-between mb-2">
                        <p class="mt-2 mb-0 flex-1">
                            Completed Sessions
                        </p>
                        <div class="flex-1 text-right">
                            <v-chip small class="ma-2">
                                73
                            </v-chip>
                        </div>
                    </div>
                    <div v-if="mainrole!=='Customer'" class="d-flex align-center justify-space-between mb-2">
                        <p class="mt-2 mb-0 flex-1">
                            Earned in June
                        </p>
                        <div class="flex-1 text-right">
                            <v-chip small class="ma-2">
                                200 €
                            </v-chip>
                        </div>
                    </div>   
                    <div v-if="mainrole!=='Customer'" class="d-flex align-center justify-space-between mb-2">
                        <p class="mt-2 mb-0 flex-1">
                            Profile Visitors (Last 30 Days)
                        </p>
                        <div class="flex-1 text-right">
                            <v-chip small class="ma-2">
                                37
                            </v-chip>
                        </div>
                    </div> 
                    
                                 
                </v-card-text>
            </base-card>
        </v-col>
        <v-col cols="12" lg="9">
            <div class="d-flex align-center">
                <p class="font-weight-medium mr-3 mb-3">
                    Setup your profile
                </p>
                <v-divider />
            </div>

            <!-- <base-card class="mb-4" v-if="help_professionalquestion">
                <v-card-text class="">
                    <div class="d-flex align-center justify-space-between">
                        <div>
                            <div class="card-title">
                                Are you a professional?
                            </div>
                            <p class=" font-weight-medium">
                                Let us know if you are a professional, so that you can setup your account and start getting clients!
                            </p>
                            <p>
                                <v-btn class="text-capitalize" outlined color="primary" :to="'/mng/professionalsetup'">
                                    Yes, I am a professional
                                </v-btn>
                            </p>
                            <p>
                                <v-btn icon class="text-capitalize" outlined color="error" @click="()=> { help_professionalquestion=false }">
                                    No, I am not a professional
                                </v-btn>
                                <span class="ml-10">(You will be able to switch to a professional profile later, if you want.)</span>
                            </p>
                        </div>
                        <div>
                            <v-img
                                width="128"
                                height="128"
                                class="d-sm-none d-md-block"
                                src="@/assets/images/illustrations/business_deal.svg"
                                alt=""
                            ></v-img>
                        </div>
                    </div>
                </v-card-text>
            </base-card> -->

            <base-card class="mb-4">
                <v-card-text>
                    <v-tabs>
                        <v-tab class="text-capitalize">
                            Profile                           
                        </v-tab>
                        <v-tab v-if="mainrole==='Therapist'" class="text-capitalize">
                            Therapist Information
                        </v-tab>
                        <v-tab v-if="mainrole==='Business Owner'" class="text-capitalize">
                            Business Information
                        </v-tab>
                        <v-tab-item transition="true">
                            <v-row class="pb-0 mb-0 pt-4">
                                <v-col cols="12" class="pb-0 mb-0">
                                    <h6 class="mb-0 ml-4">User Info</h6>                                    
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="4" class="pt-0">
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <v-text-field
                                                    label="E-mail"
                                                    v-model="email"
                                                />
                                            </v-list-item-content>                                            
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <v-text-field
                                                    label="Mobile No"
                                                    v-model="mobile"
                                                />
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0">
                                    <v-list>                                        
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <v-text-field
                                                    label="Firstname"
                                                    v-model="firstname"
                                                />
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <v-text-field
                                                    label="Lastname"
                                                    v-model="lastname"
                                                />
                                            </v-list-item-content>                                            
                                        </v-list-item>                                        
                                    </v-list>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0">
                                    <v-list>                                        
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <v-select         
                                                    placeholder=""                                                            
                                                    v-model="language"
                                                    :items="languagesOptions"                 
                                                    label="Language" 

                                                    :rules="[]"     
                                                    required                                                         
                                                    :clearable="true"                      
                                                /> 
                                            </v-list-item-content>
                                        </v-list-item>                                       
                                    </v-list>
                                </v-col>
                            </v-row>
                            <v-row class="pb-0 mb-0 pt-4">
                                <v-col cols="12" class="pb-0 mb-0">
                                    <h6 class="mb-0 ml-4">Customer Info</h6>                                    
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="4" class="pt-0">
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <v-select         
                                                    placeholder=""                                                            
                                                    v-model="gender"
                                                    :items="genderOptions"                 
                                                    label="Genetic Gender" 

                                                    :rules="[]"     
                                                    required                                                         
                                                    :clearable="true"                      
                                                />
                                            </v-list-item-content>                                            
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <v-select         
                                                    placeholder=""                                                            
                                                    v-model="age"
                                                    :items="ageOptions"                 
                                                    label="Age" 

                                                    :rules="[]"     
                                                    required                                                         
                                                    :clearable="true"                      
                                                />
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>                                
                            </v-row>
                            <v-row class="pb-0 mb-0 pt-4">
                                <v-col cols="12" class="pb-4 mb-4">
                                    <h6 class="mb-0 ml-4">Locations</h6>                                    
                                    <span class="mb-0 ml-4">Choose the location(s) where you are going to be having the appointments at</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pt-0 mt-4">
                                    <template v-for="(contact, index) in customerLocations">
                                        <div :key="'cont_'+index">
                                            <LocationEditor :key="'cont_'+index" :index="index" v-model="customerLocations[index]" />
                                        </div>
                                    </template>         
                                    <v-list>                                   
                                    <v-list-item>
                                        <v-list-item-content class="pb-0">                                            
                                            <v-btn class="text-capitalize"                                 
                                                block 
                                                color="primary"                                 
                                                dark                
                                                :loading="false"  
                                                x-large
                                                @click="customerLocations.push(createContactObject())"                                            
                                            >
                                                <v-icon left>mdi-plus-outlined</v-icon>
                                                Add Another Location
                                            </v-btn>   
                                            
                                        </v-list-item-content>
                                    </v-list-item>                                   
                                </v-list>                            
                                </v-col>                                
                            </v-row>

                        </v-tab-item>
                        <v-tab-item v-if="mainrole==='Therapist'">
                            <!-- <pre>
                                {{ JSON.stringify(therapist_svc_fieldgroup, null, 2) }}
                            </pre> -->
                            <h3 class="ml-3 mt-3">Services & Experience</h3>
                            <TherapistServicesAndExperienceFieldGroup v-model="therapist_svc_fieldgroup" :occupations="occupations" />
                            <h3 class="ml-3">Booking</h3>
                            <TherapistBookingFieldGroup v-model="therapist_booking_fieldgroup" />
                            <h3 class="ml-3">Location</h3>
                            <TherapistLocationSelector v-model="therapist_location_fieldgroup" :prefectures="prefectures" :cities="cities" />


                            <v-row>
                                <v-col cols="12" md="12">
                                    <base-card>
                                        <v-card-title>
                                            <div class="card-title">Additional Photos</div><span>
                                                <v-btn class="text-capitalize"                                                                                     
                                                    color="primary"                                 
                                                    dark             
                                                    small   
                                                    style="margin-top: -20px; margin-left: 20px;"
                                                                                                                                     
                                                >                                                    
                                                    + Upload
                                                </v-btn>
                                            </span>
                                        </v-card-title>
                                        <v-card-text>
                                            <vueper-slides
                                                class="no-shadow"
                                                :visible-slides="4"
                                                slide-multiple
                                                fixed-height="350px"
                                                :gap="2"
                                                :slide-ratio="1 / 2"
                                                :dragging-distance="200"
                                                :breakpoints="{
                                                    800: {visibleSlides: 2, slideMultiple: 2},
                                                    400: {visibleSlides: 1}
                                                }"
                                            >
                                                <!-- <vueper-slide v-for="(slide, index) in sliderImage" :key="index" :image="slide.img" /> -->
                                                <vueper-slide
                                                    v-for="(card, index) in cards"
                                                    :key="index"
                                                >
                                                    <template v-slot:content>
                                                        <base-card class="h-full">
                                                            <div class="">
                                                                <img
                                                                    class="md:w-6/12"
                                                                    :src="card.img"
                                                                />
                                                            </div>
                                                        </base-card>
                                                    </template>
                                                </vueper-slide>
                                            </vueper-slides>
                                        </v-card-text>
                                    </base-card>
                                </v-col>
                            </v-row>


                            
                        </v-tab-item>
                        <v-tab-item v-if="mainrole==='Business Owner'">
                            <!-- <pre>
                                {{ JSON.stringify(businessowner_businessinfo_fieldgroup, null, 2) }}
                            </pre>                                                     -->
                            <BusinessOwnerBusinessInfoFieldGroup v-model="businessowner_businessinfo_fieldgroup" />  
                            <v-row class="pb-0 mb-0 pt-4">
                                <v-col cols="12" class="pb-4 mb-0">
                                    <h6 class="mb-0 ml-4">Locations</h6>   
                                    <span class="mb-0 ml-4">Choose the location(s) and contact information of your business</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pt-0">
                                    <template v-for="(contact, index) in locations">
                                        <div :key="'cont_'+index">
                                            <LocationEditor :key="'cont_'+index" :index="index" v-model="locations[index]" />
                                        </div>
                                    </template>         
                                    <v-list>                                   
                                    <v-list-item>
                                        <v-list-item-content class="pb-0">                                            
                                            <v-btn class="text-capitalize"                                 
                                                block 
                                                color="primary"                                 
                                                dark                
                                                :loading="false"  
                                                @click="locations.push(createContactObject())"  
                                                x-large                                          
                                            >
                                                <v-icon left>mdi-plus-outlined</v-icon>
                                                Add Another Location
                                            </v-btn>   
                                            
                                        </v-list-item-content>
                                    </v-list-item>                                   
                                </v-list>                            
                                </v-col>                                
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <base-card>
                                        <v-card-title>
                                            <div class="card-title">Additional Photos</div><span>
                                                <v-btn class="text-capitalize"                                                                                     
                                                    color="primary"                                 
                                                    dark             
                                                    small   
                                                    style="margin-top: -20px; margin-left: 20px;"
                                                                                                                                     
                                                >                                                    
                                                    + Upload
                                                </v-btn>
                                            </span>
                                        </v-card-title>
                                        <v-card-text>
                                            <vueper-slides
                                                class="no-shadow"
                                                :visible-slides="4"
                                                slide-multiple
                                                fixed-height="350px"
                                                :gap="2"
                                                :slide-ratio="1 / 2"
                                                :dragging-distance="200"
                                                :breakpoints="{
                                                    800: {visibleSlides: 2, slideMultiple: 2},
                                                    400: {visibleSlides: 1}
                                                }"
                                            >
                                                <!-- <vueper-slide v-for="(slide, index) in sliderImage" :key="index" :image="slide.img" /> -->
                                                <vueper-slide
                                                    v-for="(card, index) in cards"
                                                    :key="index"
                                                >
                                                    <template v-slot:content>
                                                        <base-card class="h-full">
                                                            <div class="">
                                                                <img
                                                                    class="md:w-6/12"
                                                                    :src="card.img"
                                                                />
                                                            </div>
                                                        </base-card>
                                                    </template>
                                                </vueper-slide>
                                            </vueper-slides>
                                        </v-card-text>
                                    </base-card>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs>                    
                </v-card-text>
            </base-card>

            <!-- <base-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <div class="card-title mb-0">
                                How to build your success on Market in 3 steps
                            </div>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="4"
                            class="text-18-left text-center"
                        >
                            <v-icon class="primary--text mb-2"
                                >mdi-microphone-outline</v-icon
                            >
                            <p class="heading-label ma-0 ">Get Noticed</p>
                            <p class="text--disabled">
                                Tap into the power of social media by sharing
                                your Gig, and
                                <a class="mr-1" href="#">get expert help</a>to
                                grow your impact.
                            </p>
                            <v-btn class="" outlined color="primary"
                                >Share Your Gigs</v-btn
                            >
                        </v-col>
                        <v-col
                            cols="12"
                            lg="4"
                            class="text-18-left text-center"
                        >
                            <v-icon class="info--text mb-2"
                                >mdi-book-open-variant</v-icon
                            >
                            <p class="heading-label ma-0 ">
                                GET MORE SKILLS & EXPOSURE
                            </p>
                            <p class="text--disabled">
                                Hone your skills and expand your knowledge with
                                online courses. You’ll be able to offer more .
                            </p>
                            <v-btn class="" outlined color="primary"
                                >Explore Learn</v-btn
                            >
                        </v-col>
                        <v-col
                            cols="12"
                            lg="4"
                            class="text-18-left text-center"
                        >
                            <v-icon class="warning--text mb-2"
                                >mdi-trophy</v-icon
                            >
                            <p class="heading-label ma-0 ">GET NOTICED</p>
                            <p class="text--disabled">
                                Tap into the power of social media by sharing
                                your Gig, and
                                <a class="mr-1" href="#">get expert help</a>to
                                grow your impact.
                            </p>
                            <v-btn class="" outlined color="primary"
                                >Watch Free Course</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card-text>
            </base-card> -->

            <v-row>
                <!-- <v-col cols="12" md="6">
                    <base-card class="h-full py-5">
                        <v-card-text class="d-flex align-start ">
                            <v-icon color="primary" class="mr-2"
                                >mdi-lightbulb-on-outline</v-icon
                            >
                            <div>
                                <h5 class="font-weight-bold body-1">
                                    Tip #1: Business brings business
                                </h5>
                                <p class="ma-0">
                                    Every order counts! The more orders you get,
                                    the more successful you’ll become.
                                </p>
                            </div>
                        </v-card-text>
                    </base-card>
                </v-col>
                <v-col cols="12" md="6">
                    <base-card class="h-full py-5">
                        <v-card-text class="d-flex align-start">
                            <v-icon color="primary" class="mr-2"
                                >mdi-lightbulb-on-outline</v-icon
                            >
                            <div>
                                <h5 class="font-weight-bold body-1">
                                    Tip #2: Keep the cycle going & win buyers
                                </h5>
                                <p class="ma-0">
                                    Get an order, provide quality service, and
                                    get great reviews. You’ll get more traffic,
                                    and more orders.
                                </p>
                            </div>
                        </v-card-text>
                    </base-card>
                </v-col> -->

                <!-- <v-col cols="12">
                    <v-carousel
                        cycle
                        height="100%"
                        hide-delimiters
                        show-arrows-on-hover
                        :light="!$vuetify.theme.dark"
                    >
                        <v-carousel-item v-for="(slide, i) in slides" :key="i">
                            <v-sheet>
                                <v-row class="" align="center" justify="center">
                                    <div class="d-flex pa-4">
                                        <img
                                            class="mr-2"
                                            height="65"
                                            width="65"
                                            :src="slide.img"
                                            alt=""
                                        />
                                        <div>
                                            <div class="card-title">
                                                {{ slide.title }}
                                            </div>
                                            <p>{{ slide.paragraph }}</p>
                                            <v-btn color="primary"
                                                >learn more</v-btn
                                            >
                                        </div>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
                </v-col> -->
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import api from "src/api/index";
import LocationEditor from "@/components/home/LocationEditor";
import TherapistServicesAndExperienceFieldGroup from "@/components/home/TherapistServicesAndExperienceFieldGroup";
import TherapistBookingFieldGroup from "@/components/home/TherapistBookingFieldGroup";
import TherapistLocationSelector from "@/components/home/TherapistLocationSelector";
import BusinessOwnerBusinessInfoFieldGroup from "@/components/home/BusinessOwnerBusinessInfoFieldGroup";


import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Profile Edit'
    },
    components: {
        LocationEditor,
        TherapistServicesAndExperienceFieldGroup,
        TherapistBookingFieldGroup,
        TherapistLocationSelector,
        BusinessOwnerBusinessInfoFieldGroup,
        VueperSlides, 
        VueperSlide 
    },
    data() {
        return {
            mainrole: 'Business Owner', //Therapist, Business Owner, Customer


            help_professionalquestion: true,
            help_visibility: 'Hidden',


            //CUSTOMER INFO
            completedsessioncount: null,

            //CUSTOMER FIELDS
            email: "apapadatosdev@gmail.com",
            mobile: "6995322916",
            firstname: "Alex",
            lastname: "Padatos",
            language: "en",
            gender: "Male",
            age: "35-40",            
            customerLocations: [this.createContactObject()],            


            //THERAPIST INFO
            previousMonthTotalEarnings: null,

            //THERAPIST FIELDS
            therapist_location_fieldgroup: {
                selectedCities: ["ΑΘΗΝΑ"],
                selectedPrefectures: ["ΑΤΤΙΚΗ"],
            },
            therapist_svc_fieldgroup: {
                experience_years: 4,
                experience_months: 6,
                expertise: ['Physiotherapy', 'Massage'],                
                caninvoice: 'yes',
                vatnumber: '15678954588'
            },
            therapist_booking_fieldgroup: {
                bookingFlow: 'immediate',
                cancelationFlow: '50percent',
            },

            //BUSINESS OWNER
            businessowner_businessinfo_fieldgroup: {
                businessCommercialName: "Olive Therapy",
                operatingSinceYear: 2018,
                ownerfullname: "Ελένη Κωσταντοπούλου",
                vatnumber: "4568789544",
                noofemployees: "1-5",
                officialservicesdescription: "Υπηρεσίες Αισθητικής & Spa",
            },

            locations: [this.createContactObject()],                        

            //STATIC REF LISTS
            languagesOptions: [{text: 'English', value: 'en'},{text: 'Ελληνικά', value: 'gr'}],
            genderOptions: ["Male", "Female", "Other"],
            ageOptions: ["<20", "20-25", "25-30", "30-35", "35-40", "40-45", "45-50", "50-55", "55-60", "60-65", "65-70", "70-75", ">75"],
            occupations: ['Cosmetology','Physiotherapy','Massage', 'Reflexology'],            
            //DYNAMIC REF LISTS
            prefectures: [],
            cities: [],

            // slides: [
            //     {
            //         img: require('@/assets/images/vue.png'),
            //         title: 'Take Course On Vue Today',
            //         paragraph:
            //             'Put your professional career in full force with courses on Learn from React. Gain new skills, earn a badge on your profile, and keep those sales rolling in.'
            //     },
            //     {
            //         img: require('@/assets/images/Sass_icon.png'),
            //         title: 'Communicate With Your Customers',
            //         paragraph:
            //             'Put your professional career in full force with courses on Learn from React. Gain new skills, earn a badge on your profile, and keep those sales rolling in.'
            //     },
            //     {
            //         img: require('@/assets/images/Html_Icon.png'),
            //         title: 'Take Course On HTML Today',
            //         paragraph:
            //             'Put your professional career in full force with courses on Learn from React. Gain new skills, earn a badge on your profile, and keep those sales rolling in.'
            //     }
            // ],





            cards: [
            {
                title: 'Beats Headphone',
                details: 'Enjoy Premium Sound',
                price: '39',
                img: require('@/assets/images/products/headphone-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            },
            {
                title: 'Beats Headphone',
                details: 'Enjoy Premium Sound',
                price: '39',
                img: require('@/assets/images/products/headphone-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            }
        ],
        slides: [
            {image: require('@/assets/images/products/headphone-1.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')},
            {image: require('@/assets/images/products/iphone-1.jpg')},
            {image: require('@/assets/images/products/speaker-1.jpg')},
            {image: require('@/assets/images/products/watch-2.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')},
            {image: require('@/assets/images/products/headphone-1.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')}
        ]
        }
    },
    methods: {
        createContactObject() {
            return {
                businessprefecture: "Περιφερειακή ενότητα Βορείου Τομέα Αθηνών",
                businessmunicipality: "Νέα Ιωνία",
                streetaddress: "Ομήρου 13",
                postalcode: "142 33", //"12356",
                phoneno: "2102 795835",
                email: "alldayhome42@gmail.com",
                latcoord: 38.036843176315436, //37.9795244,    38.036843176315436, 23.758162196827396
                lngcoord: 23.758162196827396, //23.7359515
            }
        },
    },
    async mounted() {
        const rsp = await api.hud.myhomepageinfo();        
        if(JSON.parse(localStorage.getItem("userInfo")).user.role !== "customer") {
            this.openVerticalSaasSidebarDrawer();
        }
        this.prefectures.push(...rsp.data.prefectures);
        this.cities.push(...rsp.data.areas);
    }
}
</script>
